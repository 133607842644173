import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import * as cx from './Branding.module.scss'

const RewScBranding = ({
  accentBg = 'https://resources.agentimage.com/redesign/local/modal-showcase-large.png',
  title = 'Custom Built for Agent Branding',
  description = 'Your Semi-Custom website is conceptualized by our experienced team of Art Directors and Web Designers. We can help bring your ideas to life while crafting a one-of-a-kind design that no template website can rival.',
  buttton_text = ' Learn About Our Process',
  button_url = '/',
}) => {
  return (
    <section
      className={cx.wrapper}
      style={{
        backgroundImage: `url(https://resources.agentimage.com/redesign/local/real-estate-websites/semi-custom/branding-bg.jpg)`,
      }}
    >
      <div
        className={cx.bgAccent}
        style={{
          backgroundImage: `url(${accentBg})`,
        }}
      ></div>
      <div className={`${cx.bgShadow} bg-gradient-transparent-to-white`}></div>
      <div className={cx.content}>
        <h2 className="heading-3">{title}</h2>
        <p className="default-body large">{description}</p>
        <Link
          to={button_url}
          className="primary-button large"
          aria-label={buttton_text}
        >
          {buttton_text}
        </Link>
      </div>
    </section>
  )
}

RewScBranding.propTypes = {
  accentBg: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttton_text: PropTypes.string,
  button_url: PropTypes.string,
}

export default RewScBranding
