export const reconstructGalleryItems = (items) => {
  let newArr = []

  items.map((item, index) => {
    newArr.push({
      name: item.name,
      domain: item.url,
      mobile: `${item.mobile.url}`,
      tablet: `${item.tablet.url}`,
    })
  })

  return newArr
}

export const reconstructProductSlideFeatures = (items) => {
  let newArr = []

  items.map((item, index) => {
    newArr.push({
      title: item?.title,
      description: item?.description,
      image: item?.image?.url,
    })
  })

  return newArr
}
