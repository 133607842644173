import React from 'react'

const SvgUnlimited = ({ className = '' }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 179"
      style={{ enableBackground: 'new 0 0 400 179' }}
      className={`${className} fill-current`}
    >
      <title>Unlimited</title>
      <path
        d="M3,89.4C2.8,48.3,32.9,13.2,73.3,6.8c16.5-2.6,31.8,1,46.6,7.7c20.6,9.3,39.2,21.7,57.5,34.8
		c17.5,12.5,33.7,26.7,50.7,39.8c20.6,15.9,41.7,31.1,65.5,42.1c1.4,0.6,2.8,1.2,4.3,1.8c18.4,7.3,36.3,3.7,49.8-10.8
		c12.8-13.8,16.6-30.1,10.5-47.9c-6.1-17.8-19-28.2-37.5-31.7c-8.4-1.6-16.1,0.6-23.7,3.8c-16.3,6.9-31,16.4-45.4,26.4
		c-4.4,3.1-8.8,6.2-13.1,9.4c-1.6,1.2-2.9,1.2-4.6-0.1c-7.8-6.4-15.7-12.7-23.6-19c-2.1-1.7-2.2-3.3-0.3-4.8
		c22.1-16.6,44.7-32.6,70.1-44c8.1-3.6,16.4-6.6,25.3-7.8c14.1-1.8,27.7,0.3,40.5,6.1c28.2,12.7,45.2,34.5,50.1,65
		c6.9,43.4-23.3,85.5-66.6,94.1c-17.6,3.5-33.9,0.1-49.8-7.1c-20.5-9.3-39.2-21.7-57.5-34.8c-17.2-12.3-33.3-26.1-49.9-39.1
		c-20.4-16-41.5-31.1-65.1-42.3c-3.8-1.8-7.7-3.4-11.8-4.7C85.7,40.4,76.3,42,67.2,46c-19.3,8.4-30.4,28.2-28,49.5
		c2.2,20,18.4,37,38.6,40.8c8.8,1.7,16.9-0.2,24.9-3.6c16.2-6.9,31-16.4,45.4-26.4c4.4-3,8.8-6.2,13.1-9.4c1.6-1.2,2.9-1.3,4.6,0
		c7.9,6.3,15.8,12.6,23.6,18.9c2.3,1.8,2.3,3.3,0.2,4.9c-21.9,16.5-44.3,32.3-69.4,43.7c-8.3,3.8-16.9,6.9-25.9,8.1
		c-14.1,1.8-27.7-0.3-40.5-6.1c-28.2-12.7-44.9-34.5-50.1-65c-0.3-1.5-0.5-3.1-0.5-4.6C2.9,94.3,3,91.9,3,89.4z"
      />
    </svg>
  )
}

export default SvgUnlimited
