// extracted by mini-css-extract-plugin
export var containerWrapper = "FeaturesSlider-module--container-wrapper--d0b73";
export var contentSlider = "FeaturesSlider-module--content-slider--294f8";
export var image = "FeaturesSlider-module--image--1e5be";
export var imageSlider = "FeaturesSlider-module--image-slider--9c81c";
export var inclusion = "FeaturesSlider-module--inclusion--48ab7";
export var inclusionContent = "FeaturesSlider-module--inclusion-content--86a7d";
export var inclusionSvg = "FeaturesSlider-module--inclusion-svg--c1d00";
export var itemTitle = "FeaturesSlider-module--item-title--1c91d";
export var placeholder = "FeaturesSlider-module--placeholder--cf68d";
export var title = "FeaturesSlider-module--title--5f624";