import React from 'react'

const SvgPlugAndPlay = ({ className = '' }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      style={{ enableBackground: 'new 0 0 400 400' }}
      className={`${className} fill-current`}
    >
      <title>Plug and Play</title>
      <path
        d="M389.7,160.1c-7.2-31-21.2-58.4-42.4-82.1c-17.2-19.1-37.5-34-61-44.4c-17.4-7.7-35.6-12.9-54.6-14.7
		c-10.4-1-20.7-1.6-31.1-0.8c-13.8,1-27.4,3.1-40.6,7.1c-34.9,10.6-63.9,30-87.2,58c-18.3,22-30.8,46.8-37.7,74.5
		c-2.8,11.4-4.9,22.9-5.2,34.6c-0.1,2.8-0.9,4.4-4.2,4.2c-5-0.3-10.1-0.1-15.2-0.1c-2.6,0-4.3,1.3-4.3,3.9C6,213.7,6,227,6,240.3
		c0,2.1,1.1,3.4,3.2,4c2.2,0.6,4.4,0.4,6.6,0.3c2.1-0.1,2.9,0.6,2.8,2.8c-0.1,5.2,0,10.3,0,15.5c0,3.8,1.4,6,4,6.1
		c2.8,0.1,4.6-2.4,4.6-6.4c0-5.1,0.1-10.1,0-15.2c-0.1-2.1,0.5-2.9,2.8-2.9c6.3,0.1,12.5,0.1,18.8,0c2.2,0,2.9,0.6,2.8,2.8
		c-0.1,5.7-0.1,11.4,0,17.2c0,2.8,1.8,4.7,4.2,4.4c2.4-0.3,4-1.6,3.9-4.4c-0.1-4.5,0-9,0-13.5c0-6.3,0-6.3,6.4-6.4
		c5.1-0.1,6.7-1.6,6.7-6.7c0-5.8,0-11.7,0-17.5c0-5.8,0-11.7,0-17.5c0-5-1.5-6.5-6.4-6.5c-5,0-9.9,0-14.9,0c-1,0-2.4,0.4-2.2-1.5
		c0.8-9.5,1.7-19,4-28.2c8.7-36.3,26.6-67.1,55.2-91.3c15.5-13.1,32.8-23,52.1-29.4c19.6-6.6,39.7-9.3,60.5-8.1
		c11.9,0.7,23.6,2.4,34.9,5.7C299,56.4,332,82.3,354,121.7c16.7,29.8,22.8,62,19.2,96.1c-1.9,17.8-6.6,34.8-14.1,51
		c-12.7,27.6-31.6,50.1-56.7,67.1c-21.4,14.5-45,23.2-70.9,25.8c-11.9,1.2-23.6,1-35.4-0.3c-15.9-1.8-31.4-5.6-46.3-11.7
		c-18.9-7.7-36-18-50.9-32.1c-3-2.8-6.1-5.5-10.7-4.7c-4,0.7-7,3-8,6.9c-1.1,4.1-0.1,7.8,3.2,10.8c7.5,6.9,15.4,13.4,23.9,19.1
		c29.1,19.5,61.4,29.9,96.1,32.2c18.7,1.2,37.4,0,55.8-4.9c38.9-10.3,70.7-31.6,95.5-63c22.7-28.7,35.7-61.6,38.7-98
		C394.8,197.3,394,178.6,389.7,160.1z"
      />
      <path
        d="M155.3,255.8c0,9,5.5,12.4,13.5,8.5c28.4-14,56.9-27.9,85.3-41.9c10.1-5,20.3-9.9,30.4-14.9
		c5-2.5,6.5-6.5,4.4-11c-1.1-2.4-3.3-3.4-5.5-4.5c-17.7-8.7-35.4-17.3-53.1-26c-20.9-10.2-41.8-20.5-62.6-30.7
		c-1.7-0.8-3.3-1.6-5.3-1.2c-4.9,1-7.1,3.8-7.1,9.9c0,18.7,0,37.4,0,56.1c0,0,0,0,0,0C155.3,218.6,155.2,237.2,155.3,255.8z"
      />
    </svg>
  )
}

export default SvgPlugAndPlay
