import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'

import SpliderButtons from '@components/global/buttons/SpliderButtons'
import SvgPhoneV2 from '@svg/phoneV2.inline'
import SvgSetting from '@svg/setting.inline'
import SvgPlugAndPlay from '@svg/plugAndPlay.inline'
import SvgUnlimited from '@svg/unlimited.inline'
import * as cx from './FeaturesSlider.module.scss'

const RewScFeaturesSlider = ({ title = [], items = [] }) => {
  const imageSlideRef = useRef()
  const contentSlideRef = useRef()

  useEffect(() => {
    contentSlideRef.current.sync(imageSlideRef.current.splide)
  })

  return (
    <section>
      <div className={`container-stretch ${cx.containerWrapper}`}>
        <div className="row">
          <div className={`col ${cx.imageSlider}`}>
            <Splide
              ref={imageSlideRef}
              aria-label="Features Slider"
              hasTrack={false}
              tag="div"
              options={{
                type: 'fade',
                arrows: false,
                arrowPath: false,
                pagination: false,
                perPage: 1,
                autoHeight: false,
                lazyLoad: 'sequential',
                autoplay: false,
                interval: 5000,
              }}
            >
              <SplideTrack>
                {items.map((item, index) => {
                  return (
                    <SplideSlide key={index}>
                      <canvas
                        className={cx.placeholder}
                        width={663}
                        height={461}
                      ></canvas>
                      <img
                        src={item.image}
                        alt={item.title}
                        width={663}
                        height={461}
                        className={cx.image}
                      />
                    </SplideSlide>
                  )
                })}
              </SplideTrack>
            </Splide>
          </div>
          <div className={`col ${cx.contentSlider}`}>
            <Splide
              ref={contentSlideRef}
              aria-label="Features Slider"
              hasTrack={false}
              tag="div"
              options={{
                type: 'loop',
                arrows: 2,
                arrowPath: false,
                arrowPath: false,
                pagination: false,
                perPage: 1,
                autoHeight: false,
                lazyLoad: 'sequential',
                autoplay: false,
                interval: 5000,
              }}
            >
              <SplideTrack>
                {items.map((item, index) => {
                  return (
                    <SplideSlide key={index}>
                      {title[index] !== undefined && (
                        <h2 className={`subtitle-7 ${cx.title}`}>
                          {title[index]}
                        </h2>
                      )}
                      <h3 className={`heading-2 ${cx.itemTitle}`}>
                        {item.title}
                      </h3>
                      <p className="default-body large">{item.description}</p>
                    </SplideSlide>
                  )
                })}
              </SplideTrack>
              <SpliderButtons withText />
            </Splide>
          </div>
        </div>
      </div>

      <ul className={cx.inclusion}>
        <li>
          <span className={cx.inclusionSvg}>
            <SvgPlugAndPlay />
          </span>
          <span className={`default-body large ${cx.inclusionContent}`}>
            Plug and Play Website Layouts
          </span>
        </li>
        <li>
          <span className={cx.inclusionSvg}>
            <SvgPhoneV2 />
          </span>
          <span className={`default-body large ${cx.inclusionContent}`}>
            Optimized for all screen sizes
          </span>
        </li>
        <li>
          <span className={cx.inclusionSvg}>
            <SvgUnlimited />
          </span>
          <span className={`default-body large ${cx.inclusionContent}`}>
            Create Unlimited Pages
          </span>
        </li>
        <li>
          <span className={cx.inclusionSvg}>
            <SvgSetting />
          </span>
          <span className={`default-body large ${cx.inclusionContent}`}>
            Seamless IDX integration
          </span>
        </li>
      </ul>
    </section>
  )
}

RewScFeaturesSlider.propTypes = {
  title: PropTypes.array,
  items: PropTypes.array,
}

export default RewScFeaturesSlider
