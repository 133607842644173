import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import {
  reconstructGalleryItems,
  reconstructProductSlideFeatures,
} from '@utils/reconstructArrayObject'

// Component
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import Gallery from '@components/pages/real-estate-websites/Gallery'
import Separator from '@components/pages/real-estate-websites/Separator'
import RewScFeaturesSlider from '@components/pages/real-estate-websites/semicustom/FeaturesSlider'
import RewScBranding from '@components/pages/real-estate-websites/semicustom/Branding'
import WebsiteFeaturesAlt from '@components/pages/real-estate-websites/WebsiteFeaturesAlt'
import Ads10BestWebsites from '@components/global/ads/10BestWebsites'
import AdsCompareWebsites from '@components/global/ads/CompareWebsites'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

// CSS
import * as cx from './SemiCustom.module.scss'

const ProductGallerySemiCustom = ({
  data: { page },
  pageContext: { seo, uri },
}) => {
  const product = page.product

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        bgGradientShow
        wrapperClassName={cx.banner}
        bgGradient="bg-gradient-blue-surf"
        layout="product-gallery"
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.bannerForm?.title}
        description={page?.bannerForm?.description}
        formLayout=""
        devices={{
          layout: 'TabletLandscapeMobileV2',
          mobile: `${page?.bannerForm?.mobile?.url}`,
          tablet: {
            one: `${page?.bannerForm?.laptop?.url}`,
            two: `${page?.bannerForm?.tablet?.url}`,
          },
        }}
        formTitle={page?.bannerForm?.formTitle}
        formSubtitle={page?.bannerForm?.formSubtitle}
        formSubmitLabel={page?.bannerForm?.formSubmitLabel}
      />
      <Gallery
        title={product?.gallery?.title}
        description={product?.gallery?.description}
        posts={reconstructGalleryItems(product?.gallery?.items)}
        postsPerPage={8}
        loadMoreButtonTextDesktop={`View more ${page.title} websites`}
        loadMoreButtonTextMobile={`More ${page.title} websites`}
        style="alt"
      />
      <Separator
        wrapperClassName={cx.separator}
        title={product?.separator?.title}
        titleClassName="heading-5"
        description={product?.separator?.description}
        descriptionClassName="subtitle-5"
      />
      <RewScFeaturesSlider
        // title={product?.branding?.title}
        title={['Built to Adapt', 'Built to Your Liking', 'Built to Stand Out']}
        items={reconstructProductSlideFeatures(product?.branding?.items)}
      />
      <RewScBranding
        // accentBg={product?.branding?.custom?.background?.url}
        accentBg="https://resources.agentimage.com/redesign/local/real-estate-websites/semi-custom/iso-devices.png"
        title={product?.branding?.custom?.title}
        description={product?.branding?.custom?.description}
        buttton_text={product?.branding?.custom?.text}
        button_url={product?.branding?.custom?.url}
      />
      <WebsiteFeaturesAlt
        wrapperBg={page?.productFeatures?.background?.url}
        bannerBg={page?.productFeatures?.accent?.url}
        title={page?.title}
        bannerLaptop={page?.productFeatures?.laptop?.url}
        bannerMobile={page?.productFeatures?.mobile?.url}
        bestForItems={page?.productFeatures?.bestFor}
        highlightsItems={page?.productFeatures?.highlights}
        featuresItems={page?.productFeatures?.more}
      />

      <div className="ads">
        <div className="container">
          <Ads10BestWebsites
            background="https://resources.agentimage.com/redesign/local/ads/sc-bg.jpg"
            name={page.title}
            description="See our award-winning Semi-Custom websites up close and in action!"
            descriptionClassName={cx.adsDescription}
            label="View Websites"
            image="https://resources.agentimage.com/redesign/local/ads/semi-custom-ads.png"
            imageWidth={510}
            imageHeight={391}
            invert={true}
          />
          <AdsCompareWebsites />
        </div>
      </div>

      <LeadContactForm
        version={page?.leadContactForm?.version}
        background={page?.leadContactForm?.background}
        modelOrBackground={page?.leadContactForm?.model}
        title={page?.leadContactForm?.title}
        subtitle={page?.leadContactForm?.subtitle}
        submitLabel={page?.leadContactForm?.submit}
        leadName={page?.title}
        layout="default"
        formLocation="footer"
      />
    </LayoutInnerPage>
  )
}

export default ProductGallerySemiCustom

export const pageQuery = graphql`
  query ProductGallerySemiCustomPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }

      bannerForm {
        title
        description
        laptop {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        tablet {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        formTitle
        formSubtitle
        formSubmitLabel
      }

      product: productSemiCustom {
        gallery {
          title
          description
          items {
            name
            url
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        separator {
          title
          description
        }
        branding {
          title
          items {
            title
            description
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
          custom {
            title
            description
            url
            text
            background {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
      }

      productFeatures {
        background {
          url
        }
        accent {
          url
        }
        laptop {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        mobile {
          url
          srcset
          sizes
          width
          height
          alt
          modified
        }
        bestFor {
          check
          content
        }
        highlights {
          check
          content
        }
        more {
          check
          content
        }
      }

      productAds {
        background {
          url
        }
        image {
          url
          width
          height
        }
        link
      }

      leadContactForm {
        background {
          url
          srcset
          sizes
          width
          height
        }
        version
        model
        title
        subtitle
        submit
      }
    }
  }
`
